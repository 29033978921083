import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Radio, Mic, Newspaper, Search, Upload, Menu, Crown, DollarSign } from 'lucide-react';
import { SearchOverlay } from './SearchOverlay';
import { MobileMenu } from './MobileMenu';
import { cn } from '../lib/utils';

export function Navigation() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 bg-black/95 backdrop-blur-lg border-b border-white/10 z-50">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <Link to="/" className="flex items-center gap-2">
              <Radio className="w-8 h-8 text-blue-500" />
              <span className="text-xl font-bold hidden sm:inline">AI Radio</span>
            </Link>
            
            <div className="hidden md:flex items-center gap-6">
              <Link 
                to="/" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Mic className="w-5 h-5" />
                <span>Home</span>
              </Link>
              <Link 
                to="/news" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/news' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Newspaper className="w-5 h-5" />
                <span>News</span>
              </Link>
              <Link 
                to="/plans" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/plans' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Crown className="w-5 h-5" />
                <span>Plans</span>
              </Link>
              <Link 
                to="/submit-ad" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/submit-ad' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <DollarSign className="w-5 h-5" />
                <span>Submit Ad</span>
              </Link>
              <Link 
                to="/upload" 
                className={cn(
                  "flex items-center gap-2 transition",
                  location.pathname === '/upload' ? "text-blue-400" : "hover:text-blue-400"
                )}
              >
                <Upload className="w-5 h-5" />
                <span>Upload Music</span>
              </Link>
            </div>

            <div className="flex items-center gap-2">
              <button 
                onClick={() => setIsSearchOpen(true)} 
                className="p-2 hover:bg-white/10 rounded-full transition"
              >
                <Search className="w-5 h-5" />
              </button>
              
              <button 
                onClick={() => setIsMobileMenuOpen(true)}
                className="md:hidden p-2 hover:bg-white/10 rounded-full transition"
              >
                <Menu className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <SearchOverlay isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
      <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
    </>
  );
}