import { create } from 'zustand';

export interface Content {
  id: string;
  title: string;
  artist: string;
  type: 'music' | 'podcast' | 'news' | 'sports';
  duration: number;
  imageUrl: string;
  audioUrl: string;
  description: string;
  tags: string[];
  likes: number;
  plays: number;
  createdAt: Date;
}

interface ContentState {
  currentContent: Content | null;
  queue: Content[];
  history: Content[];
  isLoading: boolean;
  error: string | null;
  setCurrentContent: (content: Content) => void;
  addToQueue: (content: Content) => void;
  removeFromQueue: (id: string) => void;
  clearQueue: () => void;
}

export const useContentStore = create<ContentState>((set, get) => ({
  currentContent: {
    id: 'welcome',
    title: 'Welcome to AI Radio',
    artist: 'AI DJ',
    type: 'music',
    duration: 180,
    imageUrl: 'https://source.unsplash.com/random/800x800?radio',
    audioUrl: 'https://example.com/welcome.mp3',
    description: 'Welcome to your personalized music experience',
    tags: ['welcome'],
    likes: 0,
    plays: 0,
    createdAt: new Date()
  },
  queue: [],
  history: [],
  isLoading: false,
  error: null,

  setCurrentContent: (content) => {
    const { currentContent, history } = get();
    if (currentContent) {
      set({ history: [currentContent, ...history].slice(0, 50) });
    }
    set({ currentContent: content });
  },

  addToQueue: (content) => {
    set((state) => ({ queue: [...state.queue, content] }));
  },

  removeFromQueue: (id) => {
    set((state) => ({
      queue: state.queue.filter((item) => item.id !== id),
    }));
  },

  clearQueue: () => {
    set({ queue: [] });
  },
}));