import React from 'react';
import { MUSIC_GENRES } from '../lib/constants/genres';
import { motion } from 'framer-motion';

export function GenreGrid() {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {MUSIC_GENRES.map((genre, index) => (
        <motion.div
          key={genre.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="group relative aspect-square overflow-hidden rounded-lg cursor-pointer"
        >
          <img
            src={genre.image}
            alt={genre.name}
            className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
          <div className="absolute bottom-0 left-0 right-0 p-4">
            <h3 className="text-lg font-bold">{genre.name}</h3>
            <p className="text-sm text-gray-300">{genre.description}</p>
          </div>
          <div className={`absolute top-4 right-4 w-2 h-2 rounded-full ${genre.color}`} />
        </motion.div>
      ))}
    </div>
  );
}