const SPOTIFY_AUTH_URL = 'https://accounts.spotify.com/authorize';
const SPOTIFY_TOKEN_URL = 'https://accounts.spotify.com/api/token';

const SCOPES = [
  'user-read-private',
  'user-read-email',
  'user-read-playback-state',
  'user-modify-playback-state',
  'user-read-currently-playing',
  'streaming',
  'playlist-read-private',
  'playlist-read-collaborative'
];

class SpotifyAuth {
  private clientId: string;
  private redirectUri: string;

  constructor() {
    this.clientId = import.meta.env.VITE_SPOTIFY_CLIENT_ID || '';
    this.redirectUri = import.meta.env.VITE_SPOTIFY_REDIRECT_URI || `${window.location.origin}/callback`;
  }

  getAuthUrl() {
    const state = this.generateRandomString(16);
    localStorage.setItem('spotify_auth_state', state);

    const params = new URLSearchParams({
      client_id: this.clientId,
      response_type: 'token',
      redirect_uri: this.redirectUri,
      scope: SCOPES.join(' '),
      state: state,
      show_dialog: 'true'
    });

    return `${SPOTIFY_AUTH_URL}?${params.toString()}`;
  }

  private generateRandomString(length: number) {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from(crypto.getRandomValues(new Uint8Array(length)))
      .map(x => possible[x % possible.length])
      .join('');
  }

  handleCallback(hash: string): string | null {
    if (!hash) return null;

    if (hash.startsWith('#')) {
      hash = hash.substring(1);
    }

    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');
    const state = params.get('state');
    const storedState = localStorage.getItem('spotify_auth_state');

    // Clear stored state
    localStorage.removeItem('spotify_auth_state');

    // Validate state to prevent CSRF attacks
    if (!state || state !== storedState) {
      throw new Error('Invalid authentication state');
    }

    if (accessToken) {
      // Store token with expiry
      const expiresIn = params.get('expires_in');
      if (expiresIn) {
        const expiryTime = Date.now() + parseInt(expiresIn) * 1000;
        localStorage.setItem('spotify_token_expiry', expiryTime.toString());
      }
      localStorage.setItem('spotify_access_token', accessToken);
      return accessToken;
    }

    return null;
  }

  getStoredToken(): string | null {
    const token = localStorage.getItem('spotify_access_token');
    const expiry = localStorage.getItem('spotify_token_expiry');
    
    if (token && expiry && parseInt(expiry) > Date.now()) {
      return token;
    }
    
    this.clearToken();
    return null;
  }

  clearToken() {
    localStorage.removeItem('spotify_access_token');
    localStorage.removeItem('spotify_token_expiry');
    localStorage.removeItem('spotify_auth_state');
  }
}

export const spotifyAuth = new SpotifyAuth();