import { Howl } from 'howler';

class AudioManager {
  private currentSound: Howl | null = null;
  private queue: string[] = [];
  private onPlayCallback: (() => void) | null = null;
  private onPauseCallback: (() => void) | null = null;
  private onEndCallback: (() => void) | null = null;
  private onErrorCallback: ((error: any) => void) | null = null;

  play(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.currentSound) {
        this.currentSound.unload();
      }

      this.currentSound = new Howl({
        src: [url],
        html5: true,
        format: ['mp3'],
        onplay: () => {
          this.onPlayCallback?.();
          resolve();
        },
        onend: () => {
          this.onEndCallback?.();
          this.playNext();
        },
        onpause: () => this.onPauseCallback?.(),
        onstop: () => this.onPauseCallback?.(),
        onloaderror: (_, error) => {
          this.onErrorCallback?.(error);
          reject(error);
        },
        onplayerror: (_, error) => {
          this.onErrorCallback?.(error);
          reject(error);
        }
      });

      this.currentSound.play();
    });
  }

  pause() {
    this.currentSound?.pause();
  }

  resume() {
    this.currentSound?.play();
  }

  stop() {
    this.currentSound?.stop();
    this.currentSound?.unload();
    this.currentSound = null;
    this.queue = [];
  }

  seek(position: number) {
    this.currentSound?.seek(position);
  }

  setVolume(volume: number) {
    this.currentSound?.volume(volume);
  }

  getDuration(): number {
    return this.currentSound?.duration() ?? 0;
  }

  getCurrentTime(): number {
    return this.currentSound?.seek() as number ?? 0;
  }

  addToQueue(url: string) {
    this.queue.push(url);
  }

  clearQueue() {
    this.queue = [];
  }

  private async playNext() {
    if (this.queue.length > 0) {
      const nextUrl = this.queue.shift();
      if (nextUrl) {
        await this.play(nextUrl);
      }
    }
  }

  onPlay(callback: () => void) {
    this.onPlayCallback = callback;
  }

  onPause(callback: () => void) {
    this.onPauseCallback = callback;
  }

  onEnd(callback: () => void) {
    this.onEndCallback = callback;
  }

  onError(callback: (error: any) => void) {
    this.onErrorCallback = callback;
  }
}

export const audioManager = new AudioManager();