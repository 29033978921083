import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { spotifyAuth } from '../services/spotify/auth';
import { spotifyService } from '../services/spotifyService';

export function SpotifyCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get the hash from the URL
        const hash = window.location.hash;
        if (!hash) {
          throw new Error('No authentication data received');
        }

        const token = spotifyAuth.handleCallback(hash);
        if (!token) {
          throw new Error('No access token received');
        }

        // Initialize Spotify service with the token
        await spotifyService.initialize(token);
                
        // Navigate back to the stored redirect URL or home
        const redirectUrl = localStorage.getItem('spotify_redirect_after_auth') || '/';
        localStorage.removeItem('spotify_redirect_after_auth');
        navigate(redirectUrl, { replace: true });
      } catch (error) {
        console.error('Spotify authentication error:', error);
        spotifyAuth.clearToken();
        navigate('/', { replace: true });
      }
    };

    handleCallback();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto mb-4" />
        <p className="text-gray-400">Connecting to Spotify...</p>
      </div>
    </div>
  );
}