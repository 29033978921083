import React from 'react';
import { spotifyAuth } from '../services/spotify/auth';

export function SpotifyConnect() {
  const handleConnect = () => {
    // Store the current page URL to redirect back after auth
    localStorage.setItem('spotify_redirect_after_auth', window.location.pathname);
    window.location.href = spotifyAuth.getAuthUrl();
  };

  return (
    <button
      onClick={handleConnect}
      className="flex items-center gap-2 bg-[#1DB954] text-white px-4 py-2 rounded-full hover:bg-[#1ed760] transition"
    >
      <img src="https://storage.googleapis.com/pr-newsroom-wp/1/2018/11/Spotify_Logo_RGB_White.png" 
           alt="Spotify" 
           className="h-4" 
      />
      Connect Spotify
    </button>
  );
}