export const DEMO_ACCOUNTS = [
  {
    email: 'demo@airadio.com',
    password: 'demo123',
    name: 'Demo User',
    subscription: 'premium' as const,
    isAdmin: false
  },
  {
    email: 'admin@airadio.com',
    password: 'admin123',
    name: 'Demo Admin',
    subscription: 'pro' as const,
    isAdmin: true
  }
] as const;

export const isDemoAccount = (email: string) => 
  DEMO_ACCOUNTS.some(account => account.email === email);

export const getDemoAccount = (email: string) =>
  DEMO_ACCOUNTS.find(account => account.email === email);