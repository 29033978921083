import React from 'react';
import { useAuthStore } from '../store/authStore';
import { useNavigate } from 'react-router-dom';
import { DEMO_ACCOUNTS } from '../lib/constants/demoAccounts';
import { motion } from 'framer-motion';
import { User, Shield } from 'lucide-react';

export function DemoLogin() {
  const { login } = useAuthStore();
  const navigate = useNavigate();

  const handleDemoLogin = (account: typeof DEMO_ACCOUNTS[0]) => {
    login({
      id: Math.random().toString(36).substr(2, 9),
      email: account.email,
      name: account.name,
      subscription: account.subscription,
      isAdmin: account.isAdmin
    });
    navigate('/');
  };

  return (
    <div className="mt-8 space-y-4">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-700"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-gray-900 text-gray-400">Quick Demo Access</span>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {DEMO_ACCOUNTS.map((account) => (
          <motion.button
            key={account.email}
            onClick={() => handleDemoLogin(account)}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="flex items-center gap-3 w-full p-4 bg-gray-800 rounded-lg hover:bg-gray-700 transition text-left"
          >
            <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
              account.isAdmin ? 'bg-purple-500/20' : 'bg-blue-500/20'
            }`}>
              {account.isAdmin ? (
                <Shield className="w-6 h-6 text-purple-400" />
              ) : (
                <User className="w-6 h-6 text-blue-400" />
              )}
            </div>
            <div>
              <div className="font-medium">{account.name}</div>
              <div className="text-sm text-gray-400">{account.email}</div>
              <div className="mt-1 flex items-center gap-2">
                <span className={`text-xs px-2 py-1 rounded-full ${
                  account.subscription === 'pro' 
                    ? 'bg-purple-500/20 text-purple-400'
                    : 'bg-blue-500/20 text-blue-400'
                }`}>
                  {account.subscription.toUpperCase()}
                </span>
                {account.isAdmin && (
                  <span className="text-xs px-2 py-1 rounded-full bg-yellow-500/20 text-yellow-400">
                    ADMIN
                  </span>
                )}
              </div>
            </div>
          </motion.button>
        ))}
      </div>

      <p className="text-center text-sm text-gray-400">
        Choose a demo account to explore the platform's features
      </p>
    </div>
  );
}