import { create } from 'zustand';
import { supabase } from '../lib/supabase/client';
import type { User } from '../lib/auth/types';

interface AuthState {
  user: User | null;
  isLoading: boolean;
  error: string | null;
  login: (user: User) => void;
  logout: () => void;
  clearError: () => void;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isLoading: false,
  error: null,

  login: (user) => {
    set({ user, isLoading: false, error: null });
  },

  logout: () => {
    supabase.auth.signOut();
    set({ user: null, isLoading: false, error: null });
  },

  clearError: () => set({ error: null })
}));

// Listen for auth state changes
supabase.auth.onAuthStateChange((event, session) => {
  if (session?.user) {
    useAuthStore.getState().login({
      id: session.user.id,
      email: session.user.email || '',
      name: session.user.user_metadata.full_name || session.user.email?.split('@')[0] || '',
      subscription: 'free',
      isAdmin: false
    });
  } else {
    useAuthStore.getState().logout();
  }
});