import React, { useEffect, useState } from 'react';
import { usePlayerStore } from '../store/playerStore';
import { useAudioStore } from '../store/audioStore';
import { useContentStore } from '../store/contentStore';
import { useFavoriteStore } from '../store/favoriteStore';
import { useShareStore } from '../store/shareStore';
import { Volume2, Heart, Share2, VolumeX } from 'lucide-react';
import { cn } from '../lib/utils';
import { PlayerControls } from './Player/PlayerControls';
import { ProgressBar } from './Player/ProgressBar';
import { AudioVisualizer } from './Player/AudioVisualizer';

export function Player() {
  const [isMuted, setIsMuted] = useState(false);
  const { currentTrack } = usePlayerStore();
  const { initialize, setVolume, volume } = useAudioStore();
  const { addFavorite, removeFavorite, isFavorite } = useFavoriteStore();
  const { shareContent } = useShareStore();

  useEffect(() => {
    initialize();
  }, []);

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
  };

  const handleMuteToggle = () => {
    const newMuted = !isMuted;
    setIsMuted(newMuted);
  };

  const handleFavoriteToggle = () => {
    if (!currentTrack) return;
    
    if (isFavorite(currentTrack.id)) {
      removeFavorite(currentTrack.id);
    } else {
      addFavorite(currentTrack);
    }
  };

  const handleShare = async () => {
    if (!currentTrack) return;
    
    await shareContent(
      currentTrack.title,
      `Check out "${currentTrack.title}" by ${currentTrack.artist} on AI Radio!`,
      `${window.location.origin}/track/${currentTrack.id}`
    );
  };

  const handleSkip = () => {
    if (!canSkipTrack()) {
      alert('Upgrade your plan to unlock unlimited skips!');
      return;
    }
    // Skip track logic here
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  if (!currentTrack) return null;

  const isCurrentFavorite = isFavorite(currentTrack.id);

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black/95 text-white p-4 backdrop-blur-lg border-t border-white/10 space-y-2">
      <AudioVisualizer />
      <div className="max-w-7xl mx-auto flex items-center justify-between gap-4">
        <div className="flex items-center gap-4 min-w-[240px]">
          <img 
            src={currentTrack.imageUrl} 
            alt={currentTrack.title}
            className="w-12 h-12 rounded-md object-cover"
          />
          <div>
            <h3 className="font-medium line-clamp-1">{currentTrack.title}</h3>
            <p className="text-sm text-gray-400 line-clamp-1">{currentTrack.artist}</p>
          </div>
        </div>
        
        <div className="flex-1 max-w-2xl">
          <div className="space-y-4">
            <PlayerControls />
            <ProgressBar />
          </div>
        </div>

        <div className="flex items-center gap-4 min-w-[240px] justify-end">
          <div className="flex items-center gap-2">
            <button onClick={handleMuteToggle} className="hover:text-blue-400 transition">
              {isMuted ? <VolumeX className="w-5 h-5" /> : <Volume2 className="w-5 h-5" />}
            </button>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              className="w-24"
            />
          </div>
          <button 
            onClick={handleFavoriteToggle}
            className={cn(
              "hover:text-blue-400 transition",
              isCurrentFavorite && "text-blue-500"
            )}
          >
            <Heart className={cn("w-5 h-5", isCurrentFavorite && "fill-current")} />
          </button>
          <button 
            onClick={handleShare}
            className="hover:text-blue-400 transition"
          >
            <Share2 className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}