export const MUSIC_GENRES = [
  {
    id: 'pop',
    name: 'Pop',
    description: 'Contemporary popular music',
    color: 'bg-pink-500',
    image: 'https://images.unsplash.com/photo-1514320291840-2e0a9bf2a9ae?q=80&w=800'
  },
  {
    id: 'rock',
    name: 'Rock',
    description: 'Guitar-driven sound with strong rhythms',
    color: 'bg-red-500',
    image: 'https://images.unsplash.com/photo-1498038432885-c6f3f1b912ee?q=80&w=800'
  },
  {
    id: 'hiphop',
    name: 'Hip Hop',
    description: 'Urban culture and rhythmic music',
    color: 'bg-purple-500',
    image: 'https://images.unsplash.com/photo-1547355253-ff0740f6e8c1?q=80&w=800'
  },
  {
    id: 'electronic',
    name: 'Electronic',
    description: 'Synthesized sounds and beats',
    color: 'bg-blue-500',
    image: 'https://images.unsplash.com/photo-1571330735066-03aaa9429d89?q=80&w=800'
  },
  {
    id: 'jazz',
    name: 'Jazz',
    description: 'Improvisational and smooth',
    color: 'bg-yellow-500',
    image: 'https://images.unsplash.com/photo-1415201364774-f6f0bb35f28f?q=80&w=800'
  },
  {
    id: 'classical',
    name: 'Classical',
    description: 'Traditional orchestral music',
    color: 'bg-emerald-500',
    image: 'https://images.unsplash.com/photo-1507838153414-b4b713384a76?q=80&w=800'
  },
  {
    id: 'rnb',
    name: 'R&B',
    description: 'Rhythm and blues',
    color: 'bg-indigo-500',
    image: 'https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?q=80&w=800'
  },
  {
    id: 'indie',
    name: 'Indie',
    description: 'Independent and alternative',
    color: 'bg-orange-500',
    image: 'https://images.unsplash.com/photo-1524650359799-842906ca1c06?q=80&w=800'
  }
] as const;