import React from 'react';
import { X, Mic, Newspaper, Upload, Crown, DollarSign, Radio } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MobileMenu({ isOpen, onClose }: MobileMenuProps) {
  const location = useLocation();

  if (!isOpen) return null;

  const menuItems = [
    { path: '/', icon: <Mic className="w-6 h-6" />, label: 'Home' },
    { path: '/news', icon: <Newspaper className="w-6 h-6" />, label: 'News' },
    { path: '/plans', icon: <Crown className="w-6 h-6" />, label: 'Plans' },
    { path: '/submit-ad', icon: <DollarSign className="w-6 h-6" />, label: 'Submit Ad' },
    { path: '/upload', icon: <Upload className="w-6 h-6" />, label: 'Upload Music' },
  ];

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/95 backdrop-blur-lg z-50 md:hidden"
        >
          <div className="flex flex-col h-full">
            <div className="flex items-center justify-between p-4 border-b border-white/10">
              <Link to="/" className="flex items-center gap-2" onClick={onClose}>
                <Radio className="w-8 h-8 text-blue-500" />
                <span className="text-xl font-bold">AI Radio</span>
              </Link>
              <button
                onClick={onClose}
                className="p-2 hover:bg-white/10 rounded-full transition"
              >
                <X className="w-6 h-6" />
              </button>
            </div>

            <nav className="flex-1 px-4 py-8">
              <motion.div 
                className="space-y-2"
                initial="closed"
                animate="open"
                variants={{
                  open: {
                    transition: { staggerChildren: 0.1, delayChildren: 0.2 }
                  },
                  closed: {
                    transition: { staggerChildren: 0.05, staggerDirection: -1 }
                  }
                }}
              >
                {menuItems.map((item) => (
                  <motion.div
                    key={item.path}
                    variants={{
                      open: { y: 0, opacity: 1 },
                      closed: { y: 20, opacity: 0 }
                    }}
                  >
                    <Link 
                      to={item.path}
                      onClick={onClose}
                      className={`flex items-center gap-4 p-4 rounded-lg transition ${
                        location.pathname === item.path 
                          ? 'bg-blue-500/20 text-blue-400' 
                          : 'hover:bg-white/10'
                      }`}
                    >
                      {item.icon}
                      <span className="text-lg">{item.label}</span>
                    </Link>
                  </motion.div>
                ))}
              </motion.div>
            </nav>

            <div className="p-4 border-t border-white/10">
              <p className="text-sm text-gray-400 text-center">
                AI Radio - Your personalized music experience
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}