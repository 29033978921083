import React from 'react';
import { usePlayerStore } from '../../store/playerStore';

export function ProgressBar() {
  const { currentTime, duration, seek } = usePlayerStore();
  
  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex items-center gap-2 w-full">
      <span className="text-sm text-gray-400 w-12 text-right">
        {formatTime(currentTime)}
      </span>
      
      <div className="relative flex-1 group">
        <input
          type="range"
          min="0"
          max={duration || 0}
          value={currentTime}
          onChange={(e) => seek(parseFloat(e.target.value))}
          className="w-full h-1 bg-gray-700 rounded-full appearance-none cursor-pointer"
          style={{
            backgroundSize: `${(currentTime / (duration || 1)) * 100}% 100%`
          }}
        />
        
        <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black px-2 py-1 rounded text-xs opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
          {formatTime(currentTime)}
        </div>
      </div>
      
      <span className="text-sm text-gray-400 w-12">
        {formatTime(duration)}
      </span>
    </div>
  );
}