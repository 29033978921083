import SpotifyWebApi from 'spotify-web-api-js';

class SpotifyService {
  private spotify: SpotifyWebApi.SpotifyWebApiJs;
  private accessToken: string | null = null;
  private player: Spotify.Player | null = null;

  constructor() {
    this.spotify = new SpotifyWebApi();
  }

  async initialize(token: string) {
    this.accessToken = token;
    this.spotify.setAccessToken(token);
    await this.initializePlayer();
  }

  private async initializePlayer() {
    if (!window.Spotify) {
      const script = document.createElement('script');
      script.src = 'https://sdk.scdn.co/spotify-player.js';
      document.body.appendChild(script);

      await new Promise<void>((resolve) => {
        window.onSpotifyWebPlaybackSDKReady = () => resolve();
      });
    }

    this.player = new window.Spotify.Player({
      name: 'AI Radio Web Player',
      getOAuthToken: (cb) => cb(this.accessToken || ''),
    });

    await this.player.connect();
  }

  async play(uri?: string) {
    if (!this.player) return;
    if (uri) {
      await this.spotify.play({ uris: [uri] });
    } else {
      await this.player.resume();
    }
  }

  async pause() {
    if (this.player) {
      await this.player.pause();
    }
  }

  async resume() {
    if (this.player) {
      await this.player.resume();
    }
  }

  async setVolume(volume: number) {
    if (this.player) {
      await this.player.setVolume(volume);
    }
  }

  async searchTracks(query: string) {
    try {
      const response = await this.spotify.searchTracks(query, { limit: 20 });
      return response.tracks?.items.map(track => ({
        id: track.id,
        title: track.name,
        artist: track.artists[0].name,
        album: track.album.name,
        coverUrl: track.album.images[0]?.url,
        previewUrl: track.preview_url,
        popularity: track.popularity,
        source: 'Spotify'
      })) || [];
    } catch (error) {
      console.error('Error searching Spotify tracks:', error);
      return [];
    }
  }

  async getRecommendations(seed_genres: string[] = ['pop']) {
    try {
      const response = await this.spotify.getRecommendations({
        seed_genres,
        limit: 20
      });
      
      return response.tracks.map(track => ({
        id: track.id,
        title: track.name,
        artist: track.artists[0].name,
        album: track.album.name,
        coverUrl: track.album.images[0]?.url,
        previewUrl: track.preview_url,
        popularity: track.popularity,
        source: 'Spotify'
      }));
    } catch (error) {
      console.error('Error getting Spotify recommendations:', error);
      return [];
    }
  }

  async getPlaylistTracks(playlistId: string) {
    try {
      const response = await this.spotify.getPlaylistTracks(playlistId);
      return response.items.map(item => ({
        id: item.track.id,
        title: item.track.name,
        artist: item.track.artists[0].name,
        album: item.track.album.name,
        coverUrl: item.track.album.images[0]?.url,
        previewUrl: item.track.preview_url,
        popularity: item.track.popularity,
        source: 'Spotify'
      }));
    } catch (error) {
      console.error('Error getting playlist tracks:', error);
      return [];
    }
  }

  async getCurrentlyPlaying() {
    try {
      const response = await this.spotify.getMyCurrentPlayingTrack();
      if (!response || !response.item) return null;
      
      const track = response.item;
      return {
        id: track.id,
        title: track.name,
        artist: track.artists[0].name,
        album: track.album.name,
        coverUrl: track.album.images[0]?.url,
        previewUrl: track.preview_url,
        popularity: track.popularity,
        source: 'Spotify'
      };
    } catch (error) {
      console.error('Error getting currently playing track:', error);
      return null;
    }
  }
}

export const spotifyService = new SpotifyService();