import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Radio } from 'lucide-react';
import { motion } from 'framer-motion';
import { DemoLogin } from '../components/DemoLogin';
import { useAuthStore } from '../store/authStore';

export function AuthPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();

  React.useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-black flex items-center justify-center p-4">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full"
      >
        <div className="text-center mb-8">
          <div className="flex justify-center mb-4">
            <div className="w-16 h-16 bg-blue-500 rounded-full flex items-center justify-center">
              <Radio className="w-8 h-8 text-white" />
            </div>
          </div>
          <h1 className="text-3xl font-bold mb-2">Welcome to AI Radio</h1>
          <p className="text-gray-400">
            Choose a demo account to explore the platform
          </p>
        </div>
        
        <DemoLogin />
      </motion.div>
    </div>
  );
}