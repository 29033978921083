import { create } from 'zustand';
import { Content, useContentStore } from './contentStore';
import { audioManager } from '../lib/audio/AudioManager';

interface PlayerState {
  isPlaying: boolean;
  currentTrack: Content | null;
  queue: Content[];
  currentTime: number;
  duration: number;
  setPlaying: (playing: boolean) => void;
  setTrack: (track: Content | null) => void;
  addToQueue: (track: Content) => void;
  removeFromQueue: (id: string) => void;
  clearQueue: () => void;
  seek: (time: number) => void;
  updateProgress: (time: number, duration: number) => void;
  playTrack: (track: Content) => Promise<void>;
}

export const usePlayerStore = create<PlayerState>((set) => ({
  isPlaying: false,
  currentTrack: null,
  queue: [],
  currentTime: 0,
  duration: 0,

  setPlaying: (playing) => set({ isPlaying: playing }),
  setTrack: (track) => set({ currentTrack: track }),

  addToQueue: (track) => set((state) => ({ queue: [...state.queue, track] })),
  removeFromQueue: (id) => set((state) => ({
    queue: state.queue.filter((track) => track.id !== id)
  })),
  clearQueue: () => set({ queue: [] }),

  seek: (time) => {
    audioManager.seek(time);
  },

  updateProgress: (time, duration) => {
    set({ currentTime: time, duration });
  },

  playTrack: async (track) => {
    if (!track.audioUrl) return;

    try {
      await audioManager.play(track.audioUrl);
      set({ currentTrack: track, isPlaying: true });
    } catch (error) {
      console.error('Error playing track:', error);
      set({ isPlaying: false });
    }
  }
}));