import React from 'react';
import { useMusicStore } from '../store/musicStore';
import { ContentCard } from '../components/ContentCard';
import { PopularPodcasts } from '../components/PopularPodcasts';
import { ArtistShowcase } from '../components/ArtistShowcase';
import { GenreGrid } from '../components/GenreGrid';
import { StationList } from '../components/RadioStations/StationList';
import { MusicApproval } from '../components/AdminPanel/MusicApproval';
import { Crown, Music } from 'lucide-react';
import { useAuthStore } from '../store/authStore';

export function HomePage() {
  const { chartTracks, isLoading, error } = useMusicStore();
  const { user } = useAuthStore();

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[50vh]">
        <div className="text-center">
          <p className="text-red-400 mb-4">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 space-y-12">
      <div className="max-w-7xl mx-auto">
        <StationList />
        
        {user?.isAdmin && <MusicApproval />}
        
        <section className="mb-12">
          <div className="flex items-center gap-2 mb-6">
            <h2 className="text-2xl font-bold">Browse by Genre</h2>
            <Music className="w-5 h-5 text-blue-400" />
          </div>
          <GenreGrid />
        </section>

        <PopularPodcasts />
        
        <ArtistShowcase />

        <section className="mb-12">
          <div className="flex items-center gap-2 mb-6">
            <h2 className="text-2xl font-bold">Top Charts</h2>
            <Crown className="w-5 h-5 text-yellow-400" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {chartTracks?.map((track) => (
              <ContentCard
                key={track.id}
                content={{
                  id: track.id,
                  title: track.title,
                  artist: track.artist,
                  type: 'music',
                  duration: 0,
                  imageUrl: track.coverUrl,
                  audioUrl: track.previewUrl || '',
                  description: `#${track.chartPosition} on Charts`,
                  tags: [],
                  likes: track.popularity * 100,
                  plays: track.popularity * 1000,
                  createdAt: new Date()
                }}
              />
            ))}
          </div>
        </section>

        {isLoading && (
          <div className="flex justify-center my-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
          </div>
        )}
      </div>
    </div>
  );
}