import React from 'react';
import { Play, Pause, SkipForward, SkipBack, Repeat, Shuffle } from 'lucide-react';
import { usePlayerStore } from '../../store/playerStore';
import { useSubscriptionStore } from '../../store/subscriptionStore';
import { cn } from '../../lib/utils';

export function PlayerControls() {
  const { isPlaying, setPlaying } = usePlayerStore();
  const { canSkipTrack } = useSubscriptionStore();
  const [shuffle, setShuffle] = React.useState(false);
  const [repeat, setRepeat] = React.useState(false);

  return (
    <div className="flex items-center gap-4">
      <button
        onClick={() => setShuffle(!shuffle)}
        className={cn(
          "p-2 rounded-full transition-colors",
          shuffle ? "text-blue-400 hover:text-blue-300" : "hover:text-gray-300"
        )}
      >
        <Shuffle className="w-5 h-5" />
      </button>

      <button
        onClick={() => {/* Handle previous */}}
        disabled={!canSkipTrack()}
        className="p-2 hover:text-gray-300 disabled:opacity-50 disabled:hover:text-white"
      >
        <SkipBack className="w-5 h-5" />
      </button>

      <button
        onClick={() => setPlaying(!isPlaying)}
        className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center hover:bg-blue-600 transition"
      >
        {isPlaying ? (
          <Pause className="w-5 h-5" />
        ) : (
          <Play className="w-5 h-5" />
        )}
      </button>

      <button
        onClick={() => {/* Handle next */}}
        disabled={!canSkipTrack()}
        className="p-2 hover:text-gray-300 disabled:opacity-50 disabled:hover:text-white"
      >
        <SkipForward className="w-5 h-5" />
      </button>

      <button
        onClick={() => setRepeat(!repeat)}
        className={cn(
          "p-2 rounded-full transition-colors",
          repeat ? "text-blue-400 hover:text-blue-300" : "hover:text-gray-300"
        )}
      >
        <Repeat className="w-5 h-5" />
      </button>
    </div>
  );
}