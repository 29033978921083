import { create } from 'zustand';
import { spotifyService } from '../services/spotifyService';
import { useMusicUploadStore } from './musicUploadStore';
import { useAdStore } from './adStore';
import { useNewsStore } from './newsStore';
import { openAIService } from '../services/openAIService';

interface Station {
  id: string;
  name: string;
  frequency: string;
  genre: string;
  description: string;
  isCommunity?: boolean;
}

interface RadioState {
  currentStation: Station | null;
  currentTrack: {
    title: string;
    artist: string;
    audioUrl: string;
    type?: 'music' | 'news' | 'ad';
    metadata?: {
      newsHeadline?: string;
      adText?: string;
      genre?: string;
    };
  } | null;
  isPlaying: boolean;
  volume: number;
  listeningStartTime: number | null;
  totalListeningTime: number;
  setStation: (station: Station) => Promise<void>;
  setPlaying: (playing: boolean) => void;
  setVolume: (volume: number) => void;
  playNextTrack: () => Promise<void>;
  updateListeningTime: () => void;
  getListeningStats: () => { current: number; total: number };
}

export const useRadioStore = create<RadioState>((set, get) => ({
  currentStation: null,
  currentTrack: null,
  isPlaying: false,
  volume: 0.8,
  listeningStartTime: null,
  totalListeningTime: 0,

  setStation: async (station) => {
    set({ currentStation: station });
    if (station.isCommunity) {
      await get().playNextTrack();
    }
  },

  setPlaying: (playing) => {
    if (playing) {
      if (get().currentStation?.isCommunity) {
        get().playNextTrack();
      } else {
        spotifyService.resume();
      }
      set({ listeningStartTime: Date.now() });
    } else {
      if (get().currentStation?.isCommunity) {
        // Handle community radio pause
      } else {
        spotifyService.pause();
      }
      get().updateListeningTime();
      set({ listeningStartTime: null });
    }
    set({ isPlaying: playing });
  },

  setVolume: (volume) => {
    spotifyService.setVolume(volume);
    set({ volume });
  },

  playNextTrack: async () => {
    const { currentStation } = get();
    if (!currentStation) return;
    
    // Check if we should play an ad or news first
    const shouldPlayAd = Math.random() < 0.2; // 20% chance
    const shouldPlayNews = Math.random() < 0.15; // 15% chance
    
    if (shouldPlayAd) {
      const ads = useAdStore.getState().getActiveAds();
      if (ads.length > 0) {
        const ad = ads[0];
        await openAIService.generateSpeech(
          `Quick message from our sponsor: ${ad.text}`,
          true
        );
        useAdStore.getState().updateAnnouncementCount(ad.id);
        return;
      }
    }
    
    if (shouldPlayNews) {
      const articles = useNewsStore.getState().articles;
      if (articles.length > 0) {
        const article = articles[Math.floor(Math.random() * articles.length)];
        await openAIService.generateSpeech(
          `Breaking news: ${article.title}`,
          true
        );
        return;
      }
    }

    try {
      // Get tracks based on station genre
      const tracks = await spotifyService.getPlaylistTracks(currentStation.genre);
      if (tracks.length === 0) {
        set({ currentTrack: null, isPlaying: false });
        return;
      }

      const randomTrack = tracks[Math.floor(Math.random() * tracks.length)];
      
      // Generate AI DJ announcement
      const announcement = await openAIService.generateAnnouncement('intro', {
        title: randomTrack.name,
        artist: randomTrack.artists[0].name,
        genre: currentStation.genre
      });
      
      await openAIService.generateSpeech(announcement, true);
    
      await spotifyService.playTrack(randomTrack.uri);
      set({
        currentTrack: {
          title: randomTrack.name,
          artist: randomTrack.artists[0].name,
          audioUrl: randomTrack.preview_url || '',
          type: 'music',
          metadata: {
            genre: currentStation.genre
          }
        }, 
        isPlaying: true,
        listeningStartTime: Date.now()
      });
    } catch (error) {
      console.error('Error playing track:', error);
      set({ currentTrack: null, isPlaying: false });
      // Try next track
      await get().playNextTrack();
    }
  },

  updateListeningTime: () => {
    const { listeningStartTime } = get();
    if (!listeningStartTime) return;
    
    const listenedMinutes = Math.floor((Date.now() - listeningStartTime) / (1000 * 60));
    set((state) => ({
      totalListeningTime: state.totalListeningTime + listenedMinutes
    }));
  },

  getListeningStats: () => {
    const { listeningStartTime, totalListeningTime } = get();
    const currentSession = listeningStartTime
      ? Math.floor((Date.now() - listeningStartTime) / (1000 * 60))
      : 0;
    return {
      current: currentSession,
      total: totalListeningTime + currentSession
    };
  }
}));