import React, { useEffect, useState } from 'react';
import { Clock, TrendingUp } from 'lucide-react';
import { useRadioStore } from '../../store/radioStore';
import { motion } from 'framer-motion';

export function ListeningStats() {
  const { getListeningStats, isPlaying } = useRadioStore();
  const [stats, setStats] = useState({ current: 0, total: 0 });

  useEffect(() => {
    if (!isPlaying) return;

    const interval = setInterval(() => {
      setStats(getListeningStats());
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying, getListeningStats]);

  return (
    <div className="bg-gray-800 rounded-lg p-4 mb-6">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
            <Clock className="w-4 h-4" />
            Current Session
          </div>
          <motion.div 
            key={stats.current}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-2xl font-bold"
          >
            {stats.current} minutes
          </motion.div>
        </div>
        
        <div>
          <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
            <TrendingUp className="w-4 h-4" />
            Total Listening Time
          </div>
          <motion.div
            key={stats.total}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-2xl font-bold"
          >
            {stats.total} minutes
          </motion.div>
        </div>
      </div>
    </div>
  );
}